import { createContext } from 'react'
import { connect } from 'socket.io-client'

import { API_ENDPOINT } from '../app/constants'
import { store } from '../app/store'
import { updateSocket } from '../features/connection/socketSlice'

export const socket = connect(API_ENDPOINT)
export const SocketContext = createContext(socket)

socket.on('connect', () => {
  store.dispatch(updateSocket(socket.id));
  console.log('<<SOCKET>> Connect --- ', socket.id);
});

socket.on('connect_error', (error) => {
  console.log('<<SOCKET>> Connect_Error --- ', socket.id, ', error:', error);
});

socket.on('disconnect', (reason) => {
  console.log('<<SOCKET>> Disconnect --- ', socket.id, 'reason:', reason);
});

let retrieveSocketIdAttemptCount = 0;
const MAX_RETRIEVE_SOCKET_ID_ATTEMPT_COUNT = 20;

const retrieveSocketId = () => {
  retrieveSocketIdAttemptCount += 1;
  if (retrieveSocketIdAttemptCount > MAX_RETRIEVE_SOCKET_ID_ATTEMPT_COUNT) {
    retrieveSocketIdAttemptCount = 0;
    return;
  }

  console.log('<<SOCKET>> retrieveSocketId (attempt:', retrieveSocketIdAttemptCount+') --- connected:', socket.connected, ', socket.id:', socket.id);
  if (socket.connected) {
    store.dispatch(updateSocket(socket.id));
    retrieveSocketIdAttemptCount = 0;
  } else {
    setTimeout(() => {
      retrieveSocketId();
    }, 500);
  }
};

socket.io.on('reconnect', (attempt) => {
  console.log('<<SOCKET>> Reconnect --- socket.id:', socket.id, ', attempt:', attempt, ', socket.connected:', socket.connected);
  retrieveSocketId();
});
