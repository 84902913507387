import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocale } from '../hooks/useLocale'
import { setSurveyData } from '~/features/survey/surveySlice'
import { NPS_SURVEY_OPEN_DELAY } from '~/app/constants'
import { useLazyGetInAppFeedbackQuery } from '~/app/services/api'
import { setNpsSurveyQuestions } from '~/features/nps/npsSlice'

export const useInAppSurvey = () => {
  const [trigger, { data }] = useLazyGetInAppFeedbackQuery()
  const dispatch = useDispatch()
  const { i18n } = useLocale()
  const triggerTimeRef = useRef(0)
  const [isSurveyVisible, setSurveyVisible] = useState(false)

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      dispatch(setNpsSurveyQuestions(data))
      const currentTime = Date.now()
      const elapsed = currentTime - triggerTimeRef.current
      const delay = NPS_SURVEY_OPEN_DELAY - elapsed

      if (delay > 0) {
        setTimeout(() => {
          setSurveyData(data)
          setSurveyVisible(true)
        }, delay)
      } else {
        setSurveyData(data)
        setSurveyVisible(true)
      }

      triggerTimeRef.current = currentTime
    }
  }, [data, dispatch])

  const hideSurvey = useCallback(() => {
    setSurveyVisible(false)
  }, [])

  const loadSurvey = useCallback(
    (userAction: any, eventId?: any) => {
      triggerTimeRef.current = Date.now()
      trigger({
        type: userAction,
        language: i18n.language,
        eventId: eventId ? eventId : undefined,
      })
    },
    [trigger, i18n.language]
  )

  return {
    loadSurvey,
    hideSurvey,
    isSurveyVisible,
  }
}
