import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Spinner,
  StackDivider,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import { MdDateRange } from 'react-icons/md'

import { Trans } from 'react-i18next'
import {
  useEnableSessionSummariesMutation,
  useGetUserHHQuery,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import { useAuth } from '../../hooks/useAuth'

const formatSummary = (summary: string, sections: string[]) => {
  if (summary.includes('. **')) {
    const lines = summary.split('\n')
    summary = lines
      .map((line) => {
        if (line.includes('. **') && line.includes(':**')) {
          return line.replace('. **', '. <b>').replace(':**', ':</b>')
        } else if (line.includes('- **') && line.includes(':**')) {
          return line.replace('- **', '- <i>').replace(':**', ':</i>')
        }
        return line
      })
      .join('\n')
  } else {
    for (const highlightedPart of sections) {
      const regex = new RegExp(highlightedPart, 'gi')
      summary = summary?.replace(regex, '<b>' + highlightedPart + '</b>')
    }
  }
  summary = summary.replace(/\n/g, '<br/>')
  return summary
}

const Item = ({ summary, sections }: any) => {
  const { t } = useLocale()
  const { start, content, verified } = summary
  const createdAt = moment(start).format('MMM D, h:mm a')

  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={4}>
      <GridItem colSpan={9} mt={2}>
        <HStack w={'full'}>
          <Flex my={2} color="gray">
            <MdDateRange size="18px" />
            <Text fontSize={'sm'} ml={1}>
              {createdAt}
            </Text>
          </Flex>
          <Spacer />
          <Text fontSize={'sm'} ml={2} color={verified ? '#00a000' : '#ff8c00'}>
            {verified ? t('verified_by_coach') : t('not_verified_by_coach')}
          </Text>
        </HStack>
        <div
          dangerouslySetInnerHTML={{ __html: formatSummary(content, sections) }}
        ></div>
      </GridItem>
    </Grid>
  )
}

const HH = () => {
  const { user } = useAuth()
  if (!user) throw new Error('User not found')
  const userId = user._id
  const { t } = useLocale()
  const mixpanel = useMixpanel()
  const toast = useToast()
  const [enableHupoHighlights] = useEnableSessionSummariesMutation()
  const {
    data: sessionSummaries,
    isLoading,
    refetch,
  } = useGetUserHHQuery(userId)

  // Show Spinner component if isLoading
  if (isLoading) {
    return <Spinner />
  }

  const onEnableHH = async () => {
    try {
      await enableHupoHighlights({}).unwrap()
      mixpanel.track('hupo_highlights_enable_success')
      toast({
        title: t('enable_hupo_highlights_success'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      refetch()
    } catch (err) {
      mixpanel.track('hupo_highlights_enable_fail')
      toast({
        title: t('enable_hupo_highlights_fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const isEnabled = sessionSummaries && sessionSummaries.enabled
  const summaries = sessionSummaries && sessionSummaries.summaries

  return (
    <>
      <Grid gap="5px" bg={'white'} rounded={'lg'} w={'full'}>
        {isLoading && (
          <Box textAlign="center" py={10} px={6}>
            <Spinner />
          </Box>
        )}
        <Grid gap="10px" w={'full'} bg={'white'} px={6} pt={3} rounded={'lg'}>
          {isEnabled ? (
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={2}
              align="stretch"
            >
              {summaries && summaries.length > 0 ? (
                summaries.map((summary: any) => (
                  <Item
                    key={summary.eventId}
                    summary={summary}
                    sections={sessionSummaries.sections}
                  />
                ))
              ) : (
                <Box textAlign="center" py={10} px={6}>
                  <Text fontSize="18px" mt={3} mb={2}>
                    {t('hh_no_data')}
                  </Text>
                </Box>
              )}
            </VStack>
          ) : (
            <Box textAlign="center" py={10} px={6}>
              <Text fontSize="18px" mt={3} mb={2}>
                {t('hh_not_enabled')}
              </Text>

              <Text fontSize="14px" color="gray">
                <Trans i18nKey="hh_not_enabled_description">
                  <strong>HupoHighlights</strong>
                </Trans>
              </Text>
              <Text fontSize="14px" color="gray" mt={6}>
                <Trans i18nKey="hupo_highlights_only_english">
                  <strong>HupoHighlights</strong>
                </Trans>
              </Text>
              <Button mt={6} colorScheme="primary" onClick={onEnableHH}>
                {t('hh_enable')}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default HH
