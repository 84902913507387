import { Box, FormControl, FormLabel, Select, Text } from '@chakra-ui/react'
import { Person, Star } from '@mui/icons-material'
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { useMemo } from 'react'
import { Check } from 'react-feather'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { PeerAssessmentItem } from '../../../types/api'
import {
  PeerAssessmentHeader,
  PeerAssessmentUsersList,
} from './peer-assessment.style'
import { useLocale } from '../../../hooks/useLocale'
import { Language } from '@mui/icons-material'
import { useFlags } from 'flagsmith/react'

export function PeerAssessmentUsers({
  questions,
  company,
  isMobileMenuOpen,
  language,
  handleChangeLanguage,
}: {
  questions: PeerAssessmentItem[]
  company?: string
  isMobileMenuOpen: boolean
  language: string
  handleChangeLanguage: (value: any) => void
}) {
  const navigate = useNavigate()
  const { user, author } = useParams<{
    user: string
    author: string
    question: string
  }>()
  const { t } = useLocale()
  const { pathname } = useLocation()

  const pendingQuestions = useMemo(() => {
    return questions.filter((question) => question.status !== 'COMPLETED')
  }, [questions])

  const completedQuestions = useMemo(() => {
    return questions.filter((question) => question.status === 'COMPLETED')
  }, [questions])

  return (
    <PeerAssessmentUsersList className={isMobileMenuOpen ? 'open' : ''}>
      <PeerAssessmentHeader>
        <img src="/images/hupo-logo.svg" alt="Hupo" />
      </PeerAssessmentHeader>
      <Box>
        <Text fontSize="sm" fontWeight="bold" color="gray.500" mt={4} mb={2}>
          {t('leadership_values')}
        </Text>
        <ListItemButton
          selected={pathname.includes('u/q/leadership-values')}
          disableRipple
          onClick={() =>
            navigate(`/peer-assessment/${author}/u/q/leadership-values`)
          }
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'warning.light' }}>
              <Star />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={company} />
        </ListItemButton>
      </Box>
      <Box>
        {pendingQuestions.length > 0 ? (
          <Text fontSize="sm" fontWeight="bold" color="gray.500" mt={4} mb={2}>
            {t('pending_requests')}
          </Text>
        ) : (
          <></>
        )}
        {pendingQuestions.map((question) => (
          <ListItemButton
            disableRipple
            selected={user === question.user._id}
            key={question.user._id}
            onClick={() =>
              navigate(
                `/peer-assessment/${author}/${question.user._id}/${
                  question.questionIndex &&
                  question.questionIndex < question.questions.length
                    ? question.questions[question.questionIndex]?._id
                    : question.questions[0]._id
                }`
              )
            }
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'error.light' }}>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={question.user.name}
              secondary={question.user.email}
            />
          </ListItemButton>
        ))}
      </Box>

      <Box>
        {completedQuestions.length > 0 ? (
          <Text fontSize="sm" fontWeight="bold" color="gray.500" mt={4} mb={2}>
            {t('completed_requests')}
          </Text>
        ) : (
          <></>
        )}
        {completedQuestions.map((question) => (
          <ListItemButton
            disableRipple
            selected={user === question.user._id}
            key={question.user._id}
            onClick={() =>
              navigate(
                `/peer-assessment/${author}/${question.user._id}/${
                  question.questions[question.questions.length - 1]._id
                }`
              )
            }
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'success.light' }}>
                <Check />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={question.user.name}
              secondary={question.user.email}
            />
          </ListItemButton>
        ))}
      </Box>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        height="auto"
        width={300}
        p={4}
      >
        <FormControl id="language">
          <FormLabel fontSize="sm" fontWeight="bold" color="gray.500">
            <Language sx={{ marginRight: '4px' }} />
            {t('language')}
          </FormLabel>
          <Select
            name="language"
            value={language}
            onChange={handleChangeLanguage}
            variant="outline"
            borderColor="gray.300"
            sx={{ paddingX: 4, paddingY: 1 }}
          >
            <option value="en">{t('english')}</option>
            <option value="ko">{t('korean')}</option>
            <option value="ja">{t('jp')}</option>
            <option value="zh">{t('zh_CN')}</option>
            <option value="tw">{t('zh_TW')}</option>
          </Select>
        </FormControl>
      </Box>
    </PeerAssessmentUsersList>
  )
}
