import { TextField, Container } from '@mui/material'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TitleDescription from './TitleDescription'
import { itemSelect } from '../npsSlice'

const TextAreaQuestion: FC<any> = ({ question }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const user: any = useSelector((state: any) => state.auth.user)

  let questionTitle = question.title.replaceAll('{{username}}', user)

  const questionDescription = question.description?.replaceAll(
    '{{username}}',
    user
  )

  const handleInput = (inputValue: string) => {
    setValue(inputValue)
    dispatch(
      itemSelect({
        questionId: question.friendlyID,
        answers: inputValue,
      })
    )
  }

  return (
    <>
      <Container>
        <TitleDescription
          title={questionTitle}
          description={questionDescription}
        />
        <TextField
          value={value}
          onChange={(event) => handleInput(event.target.value)}
          placeholder={question.placeholder || 'Enter your feedback here'}
          multiline
          rows={10}
          variant="outlined"
          fullWidth
        />
      </Container>
    </>
  )
}

export default TextAreaQuestion
