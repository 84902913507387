import { createSlice } from '@reduxjs/toolkit'

interface NpsState {
  questions: any[]
  answers: any
  triggerType: string
  surveyType: string
  surveyId: string | null
  sessionId: string | null
  eventId: string | null
  shouldAskNps: boolean
}

export const initialState: NpsState = {
  questions: [],
  answers: {},
  triggerType: '',
  surveyType: '',
  surveyId: null,
  sessionId: null,
  eventId: null,
  shouldAskNps: false,
}

const npsSlice = createSlice({
  name: 'nps',
  initialState: initialState,
  reducers: {
    setNpsSurveyQuestions: (state, { payload }) => {
      state.questions = payload.questions
      state.surveyType = payload.surveyType
      state.surveyId = payload.surveyId
      state.sessionId = payload.sessionId
    },
    itemSelect: (state, { payload }) => {
      if (payload.answers !== '') {
        state.answers[payload.questionId] = payload.answers
      } else {
        delete state.answers[payload.questionId]
      }
    },
    resetNpsData: (state) => {
      console.log('resetSurveyData')
      Object.assign(state, initialState)
    },
  },
})

export default npsSlice.reducer

export const { itemSelect, setNpsSurveyQuestions, resetNpsData } =
  npsSlice.actions
