import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TitleDescription from './TitleDescription'
import { usePostHog } from 'posthog-js/react'
import { itemSelect } from '../npsSlice'
import { useToast } from '@chakra-ui/react'

const Nps = ({ rating, setRating }: any) => {
  const options = Array.from({ length: 11 }, (_, i) => i)
  const { t } = useTranslation()

  return (
    <Box sx={{ padding: '20px 10px' }}>
      <Grid container justifyContent="space-evenly">
        {options.map((option) => (
          <Grid item xs={1} key={'nps-score-' + option}>
            <Box
              sx={{
                border: '1px solid #ff480b',
                borderRadius: 1,
                paddingY: 1,
                px: 1,
                backgroundColor:
                  rating === option ? 'primary.main' : 'background.default',
                color:
                  rating === option ? 'background.default' : 'text.primary',
                cursor: 'pointer',
                width: '100%',
                '&:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
              textAlign={'center'}
              onClick={() => setRating(option)}
            >
              <Typography sx={{ minWidth: 'max-content' }}>{option}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{ paddingTop: 1 }}>
        <Grid item xs>
          <Typography variant="body2">{t('less-likely')}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2" align="right">
            {t('very-likely')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const NpsQuestion = ({ question, onSubmit }: any) => {
  const { t } = useTranslation()
  const posthog = usePostHog()
  const toast = useToast()
  const dispatch = useDispatch()

  const [rating, setRating] = useState(-1)

  const onLocalSubmit = (rating: number) => {
    setRating(rating)
    if (rating === -1 && !question.optional) {
      toast({
        status: 'error',
        title: t('error'),
        description: t('please_select_value'),
        isClosable: true,
      })
      return
    }
    dispatch(
      itemSelect({
        questionId: question.friendlyID,
        answers: rating,
      })
    )
    onSubmit()

    posthog.capture('nps_survey_submitted', {
      source: 'bookings',
      rating,
    })
  }

  return (
    <Box>
      <TitleDescription
        title={question.title}
        description={question.description}
      />
      <Nps rating={rating} setRating={onLocalSubmit} />
    </Box>
  )
}

export default NpsQuestion
