import { Box, Button, Center, Divider, Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FaRegFilePdf } from 'react-icons/fa6'

import { useLocale } from '../../hooks/useLocale'
import { LeadershipValueChart } from './LeadershipValueChart'
import { useFlags } from 'flagsmith/react'

const HupoPerspectivesResult = ({ leadershipValueScores }: any) => {
  const [showTable, setShowTable] = useState(false)
  const { t } = useLocale()
  const flags = useFlags(['show_hp_report', 'show_hp_results'])

  const canShowLiveResults = leadershipValueScores.canShowLiveResults

  const canShowHpReport =
    flags.show_hp_report.enabled && leadershipValueScores.reportUrl

  console.log({ canShowLiveResults })
  console.log({ 'flags.show_hp_results': flags.show_hp_results })
  console.log({ leadershipValueScores })

  return (
    <Box px={4} py={2}>
      {!canShowLiveResults && !canShowHpReport && (
        <Text style={{ padding: 20, marginTop: 10, textAlign: 'center' }}>
          {leadershipValueScores.resultsPendingLabel ??
            t('report_pending_on_hr')}
        </Text>
      )}
      {canShowHpReport && (
        <Center mb={4}>
          <Button
            leftIcon={<FaRegFilePdf />}
            colorScheme="primary"
            aria-label="View detailed report"
            onClick={() =>
              window.open(leadershipValueScores.reportUrl, '_blank')
            }
            disabled={!leadershipValueScores.reportUrl}
          >
            {t('view_detailed_report')}
          </Button>
        </Center>
      )}
      {canShowLiveResults && (
        <>
          <Flex justifyContent={'space-between'} display="flex">
            <Text fontWeight="700" fontSize="18px">
              {t('leadership_value_scores')}
            </Text>
            <Box>
              <Button
                size={'sm'}
                colorScheme="primary"
                variant={showTable ? 'outline' : 'solid'}
                borderRightRadius={0}
                onClick={() => setShowTable(false)}
              >
                {t('chart_view')}
              </Button>
              <Button
                size={'sm'}
                colorScheme="primary"
                variant={showTable ? 'solid' : 'outline'}
                borderLeftRadius={0}
                onClick={() => setShowTable(true)}
              >
                {t('table_view')}
              </Button>
            </Box>
          </Flex>
          <Center>
            <LeadershipValueChart
              showTable={showTable}
              leadershipValueScores={leadershipValueScores}
            />
          </Center>
          <Box style={{ fontSize: '14px', paddingTop: '4px' }}>
            <p style={{ marginBottom: '6px' }}>{t('score-breakdown')}</p>
            <ul>
              <li>
                1 - <i>{t('self-score-1-description').replace('1 -', '')}</i>
              </li>
              <li>
                2 - <i>{t('self-score-2-description').replace('2 -', '')}</i>
              </li>
              <li>
                3 - <i>{t('self-score-3-description').replace('3 -', '')}</i>
              </li>
              <li>
                4 - <i>{t('self-score-4-description').replace('4 -', '')}</i>
              </li>
              <li>
                5 - <i>{t('self-score-5-description').replace('5 -', '')}</i>
              </li>
              <li>
                6 - <i>{t('self-score-6-description').replace('6 -', '')}</i>
              </li>
            </ul>
          </Box>
          <Divider my={4} />
          <SelfAnswers leadershipValueScores={leadershipValueScores} />
          <Divider my={4} />
          <OpenEndedAnswers leadershipValueScores={leadershipValueScores} />
        </>
      )}
    </Box>
  )
}

const SelfAnswers = ({ leadershipValueScores }: any) => {
  const { t } = useLocale()
  const selfAnswers = leadershipValueScores.selfAnswers

  return (
    <Box mt={8}>
      <Text fontWeight="700" fontSize="18px" mb={4}>
        {t('self_answers')}
      </Text>
      {selfAnswers && selfAnswers.length > 0 ? (
        <Box>
          {selfAnswers.map((answer: any, index: any) => (
            <Box key={index} mb={8}>
              <Text fontSize="16px" fontWeight={700} fontStyle={'italic'}>
                {answer.question}
              </Text>
              <Text noOfLines={[8, 8, 8]} fontSize="sm">
                {answer.responses[0]}
              </Text>
            </Box>
          ))}
        </Box>
      ) : (
        <Text>{t('no_responses_yet')}</Text>
      )}
    </Box>
  )
}

const OpenEndedAnswers = ({ leadershipValueScores }: any) => {
  const { t } = useLocale()

  return (
    <Box mt={8}>
      <Text fontWeight="700" fontSize="18px">
        {t('respondent_answers')}
      </Text>
      {leadershipValueScores.answers.length > 0 ? (
        leadershipValueScores.answers.map((answer: any, index: number) => (
          <Box key={index} mb={8} mt={index === 0 ? 4 : 0}>
            <Text fontSize="16px" fontWeight={700} fontStyle={'italic'}>
              {answer.question}
            </Text>
            {answer.descriptionItems?.length > 0 && (
              <Box>
                <ul style={{ paddingLeft: '20px' }}>
                  {answer.descriptionItems.map(
                    (response: any, index: number) => (
                      <li
                        key={'descriptionItem' + index}
                        style={{ fontStyle: 'italic', display: 'list-item' }}
                      >
                        - {response}
                      </li>
                    )
                  )}
                </ul>
              </Box>
            )}
            {answer.responses.managers.length > 0 && (
              <Box mt={2}>
                <Text fontSize="14px" fontWeight="700">
                  {t('manager')}
                </Text>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                  {answer.responses.managers.map(
                    (response: any, index: number) => (
                      <li key={index}>{response}</li>
                    )
                  )}
                </ul>
              </Box>
            )}

            {answer.responses.peers.length > 0 && (
              <Box mt={2}>
                <Text fontSize="14px" fontWeight="700">
                  {t('peers')}
                </Text>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                  {answer.responses.peers.map(
                    (response: any, index: number) => (
                      <li key={index}>{response}</li>
                    )
                  )}
                </ul>
              </Box>
            )}

            {answer.responses.reportees.length > 0 && (
              <Box mt={2}>
                <Text fontSize="14px" fontWeight="700">
                  {t('reportees')}
                </Text>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                  {answer.responses.reportees.map(
                    (response: any, index: number) => (
                      <li key={index}>{response}</li>
                    )
                  )}
                </ul>
              </Box>
            )}
          </Box>
        ))
      ) : (
        <Text>{t('no_responses_yet')}</Text>
      )}

      {Object.keys(leadershipValueScores.scoreComments).length > 0 ? (
        <Text fontWeight="700" fontSize="18px">
          {t('respondent_rating_comments')}
        </Text>
      ) : null}

      {Object.keys(leadershipValueScores.scoreComments).length > 0 ? (
        Object.keys(leadershipValueScores.scoreComments).map(
          (leadershipValueId: any, index: number) => {
            const leadershipValue =
              leadershipValueScores.values[leadershipValueId]
            const comments =
              leadershipValueScores.scoreComments[leadershipValueId]

            return (
              <Box key={index} mb={8} mt={index === 0 ? 4 : 0}>
                <Text fontSize="16px" fontWeight={700} fontStyle={'italic'}>
                  {leadershipValue}
                </Text>
                <>
                  {comments['peer'] &&
                    Object.keys(comments['peer']).length > 0 && (
                      <Box mt={2} key={'score-comments-peers'}>
                        <Text fontSize="14px" fontWeight="700">
                          {t('peers')}
                        </Text>
                        <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                          {Object.keys(comments['peer']).map(
                            (rating: any, index: number) => {
                              const responses = comments['peer'][rating]
                              return responses.map(
                                (response: any, index: number) => (
                                  <li key={index}>
                                    {response}
                                    <br />
                                    <i>{' (Score: ' + rating + ' / 6)'}</i>
                                  </li>
                                )
                              )
                            }
                          )}
                        </ul>
                      </Box>
                    )}
                </>
                <>
                  {comments['reportee'] &&
                    Object.keys(comments['reportee']).length > 0 && (
                      <Box mt={2} key={'score-comments-reportees'}>
                        <Text fontSize="14px" fontWeight="700">
                          {t('reportees')}
                        </Text>
                        <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                          {Object.keys(comments['reportee']).map(
                            (rating: any, index: number) => {
                              const responses = comments['reportee'][rating]
                              return responses.map(
                                (response: any, index: number) => (
                                  <li key={index}>
                                    {response}
                                    <br />
                                    <i>{' (Score: ' + rating + ' / 6)'}</i>
                                  </li>
                                )
                              )
                            }
                          )}
                        </ul>
                      </Box>
                    )}
                </>
                <>
                  {comments['manager'] &&
                    Object.keys(comments['manager']).length > 0 && (
                      <Box mt={2} key={'score-comments-managers'}>
                        <Text fontSize="14px" fontWeight="700">
                          {t('managers')}
                        </Text>
                        <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                          {Object.keys(comments['manager']).map(
                            (rating: any, index: number) => {
                              const responses = comments['manager'][rating]
                              return responses.map(
                                (response: any, index: number) => (
                                  <li key={index}>
                                    {response}
                                    <br />
                                    <i>{' (Score: ' + rating + ' / 6)'}</i>
                                  </li>
                                )
                              )
                            }
                          )}
                        </ul>
                      </Box>
                    )}
                </>
              </Box>
            )
          }
        )
      ) : (
        <Text>{t('no_responses_yet')}</Text>
      )}
    </Box>
  )
}

export default HupoPerspectivesResult
