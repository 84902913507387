import { Text, useToast } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  usePostManagerAssessmentDataMutation,
  usePostPeerAssessmentDataMutation,
  usePostReporteeAssessmentDataMutation,
  usePostShareManagerAssessmentWithUserMutation,
  useSaveAssessmentAnswerMutation,
} from '../../app/services/api'
import { RootState, store } from '../../app/store'
import {
  clearAssessmentData,
  itemSelect,
  setSkipQuestion,
} from './assessmentSlice'
import EmailQuestion from './questiontypes/EmailQuestion'
import FillBlanksQuestion from './questiontypes/FillBlanksQuestion'
import InfoQuestion from './questiontypes/InfoQuestion'
import RatingQuestion from './questiontypes/RatingQuestion'
import SelectionQuestion from './questiontypes/SelectionQuestion'
import StepQuestion from './questiontypes/StepQuestion'
import TextAreaQuestion from './questiontypes/TextareaQuestion'
import ValueQuestion from './questiontypes/ValueQuestion'
import MultiEmailQuestion from './questiontypes/MultiEmailQuestion'
import FeedbackEmailsQuestion from './questiontypes/FeedbackEmailsQuestion'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'

const FOCUS_AREAS = 'key-areas-to-focus'
const SELECTED_FOCUS_AREAS = 'selected-focus-areas'

const MANAGER_ASSESSMENT_FOCUS_AREAS = 'manager-assessment-key-areas-to-focus'
export const PEER_LEADERSHIP_VALUES_TO_FOCUS =
  'peer-leadership-values-user-to-focus'

export const LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID =
  'improvements-in-leadership-value-1'
export const LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID =
  'what-works-well-leadership-value-1'
export const LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID =
  'improvements-in-leadership-value-2'
export const LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID =
  'what-works-well-leadership-value-2'

export const SUGGESTIONS_TO_IMPROVE_LOW_SCORES =
  'suggestions-to-improve-low-scores'

type Props = any

const AssessmentRouter: FC<Props> = (props) => {
  const { type, isManager, isPeer, isReportee, isExt } = props
  const { user } = useAuth()
  const { t } = useLocale()
  const params = useParams() as any
  const index = parseInt(params.index)
  const navigate = useNavigate()
  const [postManagerAssessmentData] = usePostManagerAssessmentDataMutation()
  const [postPeerAssessmentData] = usePostPeerAssessmentDataMutation()
  const [postReporteeAssessmentData] = usePostReporteeAssessmentDataMutation()
  const assessmentQuestions = useSelector(
    (state: RootState) => state.assessment.questions
  )
  const assessmentAnswers = useSelector(
    (state: RootState) => state.assessment.answers
  )

  const [saveAssessmentAnswer] = useSaveAssessmentAnswerMutation()
  const [postShareManagerAssessmentWithUser] =
    usePostShareManagerAssessmentWithUserMutation()
  const question = assessmentQuestions[index]
  const dispatch = useDispatch()
  const toast = useToast()

  const focusAreasId = isManager ? MANAGER_ASSESSMENT_FOCUS_AREAS : FOCUS_AREAS

  const progress = {
    currentStep: index,
    totalSteps: assessmentQuestions.length - 1,
  }

  useEffect(() => {
    if (question && question.type === 'multiselect-readonly') {
      const initiallySelected = question.options
        .flatMap((o: any) => o.children)
        .map((item: any) => item.value)
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: initiallySelected,
        })
      )
    }
  }, [question, dispatch])

  if (!question) {
    return <Text>{t('question_not_found')}</Text>
  }

  const getNextAssessmentQuestionIndex = (index: number, question: any) => {
    let nextIndex = index + 1
    if (question.friendlyID === PEER_LEADERSHIP_VALUES_TO_FOCUS) {
      const values = store.getState().assessment.values
      const leadershipScores = store.getState().assessment.ratings
      const upToDateAssessmentAnswers = store.getState().assessment.answers
      const leadershipValuesToFocus =
        upToDateAssessmentAnswers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
      const orderedValues = values.filter((value) =>
        leadershipValuesToFocus.includes(value.value)
      )

      if (orderedValues.length === 1) {
        // skip second value questions
        dispatch(
          setSkipQuestion(LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID)
        )
        dispatch(
          setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID)
        )
        if (leadershipScores[orderedValues[0].value] === 5) {
          dispatch(
            setSkipQuestion(
              LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID
            )
          )
        } else {
          dispatch(
            setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID)
          )
        }
      } else if (orderedValues.length === 2) {
        if (leadershipScores[orderedValues[0].value] === 5) {
          dispatch(
            setSkipQuestion(
              LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID
            )
          )
        } else {
          dispatch(
            setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID)
          )
        }
        if (leadershipScores[orderedValues[1].value] === 5) {
          dispatch(
            setSkipQuestion(
              LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID
            )
          )
        } else {
          dispatch(
            setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID)
          )
        }
      }
    }

    while (nextIndex < assessmentQuestions.length - 1) {
      const nextQuestion = assessmentQuestions[nextIndex]
      const skipQuestions = store.getState().assessment.skipQuestions
      if (skipQuestions?.includes(nextQuestion.friendlyID)) {
        nextIndex++
      } else {
        break
      }
    }

    if (question.type === 'rating') {
      const totalRatingQuestions = assessmentQuestions.filter(
        (q) => q.type === 'rating'
      ).length

      const questionFriendlyId =
        focusAreasId in assessmentAnswers ? focusAreasId : SELECTED_FOCUS_AREAS

      const ratingQuestionsFromAnswersCount =
        assessmentAnswers[questionFriendlyId].length
      const firstRatingQuestionIndex = assessmentQuestions.findIndex(
        (q) => q.type === 'rating'
      )
      const ratingIndex = index - firstRatingQuestionIndex
      if (ratingIndex === ratingQuestionsFromAnswersCount - 1) {
        nextIndex += totalRatingQuestions - ratingIndex - 1
      }
    }
    if (question.type === 'value-score') {
      const totalValueScoreQuestions = assessmentQuestions.filter(
        (q) => q.type === 'value-score'
      ).length
      const valuesCount = store.getState().assessment.values.length
      const firstValueScoreQuestionIndex = assessmentQuestions.findIndex(
        (q) => q.type === 'value-score'
      )
      const ratingIndex = index - firstValueScoreQuestionIndex
      if (ratingIndex === valuesCount - 1) {
        nextIndex += totalValueScoreQuestions - ratingIndex - 1
      }
    }
    return nextIndex
  }

  const onSubmit = async () => {
    const nextIndex = getNextAssessmentQuestionIndex(index, question)

    if (index < assessmentQuestions.length - 2) {
      if (!isPeer && !isManager && !isReportee) {
        try {
          await saveAssessmentAnswer({
            answer: getCurrentAnswer(),
            rating: getCurrentRating(),
            type,
            language: params.language ?? user?.language,
            questionIndex: nextIndex,
            isFinal: index === assessmentQuestions.length - 2,
          }).unwrap()

          navigate(`../${nextIndex}`, { relative: 'path' })
        } catch (err: any) {
          toast({
            status: 'error',
            title: 'Error',
            description: err,
            isClosable: true,
          })
        }
      } else {
        navigate(`../${nextIndex}`, { relative: 'path' })
      }
    } else if (index === assessmentQuestions.length - 2) {
      const upToDateAssessmentAnswers = store.getState().assessment.answers
      const ratings = store.getState().assessment.ratings
      console.log(ratings)
      // submit the assessment ...
      try {
        if (isPeer) {
          await postPeerAssessmentData({
            answers: {
              ...upToDateAssessmentAnswers,
            },
            ratings: ratings,
            userId: params.userId,
            type,
            authorEmail: params.authorEmail,
            language: params.language,
          }).unwrap()
        } else if (isManager) {
          await postManagerAssessmentData({
            answers: {
              ...upToDateAssessmentAnswers,
            },
            ratings: ratings,
            userId: params.userId,
            isManager: true,
            language: params.language,
            authorEmail: params.authorEmail,
            type,
          }).unwrap()
        } else if (isReportee) {
          await postReporteeAssessmentData({
            answers: {
              ...upToDateAssessmentAnswers,
            },
            userId: params.userId,
            type,
            authorEmail: params.authorEmail,
            language: params.language,
          }).unwrap()
        } else {
          await saveAssessmentAnswer({
            answer: getCurrentAnswer(),
            rating: getCurrentRating(),
            type,
            language: params.language ?? user?.language,
            questionIndex: nextIndex,
            isFinal: index === assessmentQuestions.length - 2,
          }).unwrap()
        }
        navigate(`../${nextIndex}`, { relative: 'path' })
      } catch (err: any) {
        toast({
          status: 'error',
          title: 'Error',
          description: err,
          isClosable: true,
        })
      }
    } else if (index === assessmentQuestions.length - 1) {
      // Final step
      if (isPeer || isReportee || isExt) {
        window.location.assign('https://hupo.co')
      } else if (isManager) {
        const shareWithUser = store.getState().assessment.shareWithUser
        try {
          await postShareManagerAssessmentWithUser({
            userId: params.userId,
            shareWithUser,
            type,
          }).unwrap()
          window.location.assign('https://hupo.co')
        } catch (err: any) {
          toast({
            status: 'error',
            title: 'Error',
            description: err,
            isClosable: true,
          })
        }
      } else {
        navigate('/')
      }
      dispatch(clearAssessmentData())
    }
  }

  const isLastQuestion = index === assessmentQuestions.length - 1

  const getCurrentAnswer = () => {
    if (question.type !== 'rating' && question.type !== 'value-score') {
      const assessmentAnswers = store.getState().assessment.answers
      return {
        friendlyID: question.friendlyID,
        answer: assessmentAnswers[question.friendlyID],
      }
    }
    return
  }

  const getCurrentRating = () => {
    if (question.type === 'rating') {
      const assessmentRatings: any = store.getState().assessment.ratings
      const focusAreaOption = getCurrentFocusAreaOption()?.value
      return {
        focusArea: focusAreaOption,
        score: assessmentRatings[focusAreaOption],
      }
    } else if (question.type === 'value-score') {
      const assessmentRatings: any = store.getState().assessment.ratings
      const leadershipValue = getCurrentLeadershipValue()?.value
      return {
        focusArea: leadershipValue,
        score: assessmentRatings[leadershipValue],
      }
    }
    return
  }

  const getCurrentFocusAreaOption = () => {
    const firstRatingQuestionIndex = assessmentQuestions.findIndex(
      (q) => q.type === 'rating'
    )
    const ratingIndex = index - firstRatingQuestionIndex
    const questionFriendlyId =
      focusAreasId in assessmentAnswers ? focusAreasId : SELECTED_FOCUS_AREAS

    const focusAreas = assessmentAnswers[questionFriendlyId]
    const focusAreaID = focusAreas?.[ratingIndex]
    const focusAreaOptions = assessmentQuestions.find(
      (q) => q.friendlyID === questionFriendlyId
    )?.options
    return focusAreaOptions
      ?.flatMap((o: any) => o.children)
      .find((item: any) => item.value === focusAreaID)
  }

  const getCurrentLeadershipValue = () => {
    const firstValueScoreQuestionIndex = assessmentQuestions.findIndex(
      (q) => q.type === 'value-score'
    )
    const valueIndex = index - firstValueScoreQuestionIndex
    const leadershipValues = store.getState().assessment.values
    return leadershipValues[valueIndex]
  }

  switch (question.type) {
    case 'rating':
      const focusAreaOption = getCurrentFocusAreaOption()
      return (
        <RatingQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
          focusArea={focusAreaOption}
        />
      )
    case 'value-score':
      const leadershipValue = getCurrentLeadershipValue()
      return (
        <ValueQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
          leadershipValue={leadershipValue}
        />
      )
    case 'multiselect':
    case 'select':
      return (
        <SelectionQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          answer={
            assessmentAnswers ? assessmentAnswers[question.friendlyID] : []
          }
          initiallySelected={[]}
          onSubmit={onSubmit}
        />
      )
    case 'multiselect-readonly':
      const initiallySelected = question.options
        .flatMap((o: any) => o.children)
        .map((item: any) => item.value)
      return (
        <SelectionQuestion
          key={'assessment-' + index}
          readonly={true}
          progress={progress}
          question={question}
          initiallySelected={initiallySelected}
          onSubmit={onSubmit}
        />
      )
    case 'list':
      return (
        <SelectionQuestion
          key={'assessment-' + index}
          readonly={true}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
          initiallySelected={[]}
          type="list"
        />
      )
    case 'textarea':
      return (
        <TextAreaQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          answer={
            assessmentAnswers ? assessmentAnswers[question.friendlyID] : ''
          }
          onSubmit={onSubmit}
        />
      )
    case 'info':
      return (
        <InfoQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          isLast={isLastQuestion}
          isManager={isManager}
          onSubmit={onSubmit}
        />
      )
    case 'fill-blanks':
      return (
        <FillBlanksQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          answer={
            assessmentAnswers ? assessmentAnswers[question.friendlyID] : ''
          }
          onSubmit={onSubmit}
        />
      )
    case 'email':
      return (
        <EmailQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          answer={
            assessmentAnswers ? assessmentAnswers[question.friendlyID] : ''
          }
          onSubmit={onSubmit}
        />
      )
    case 'emails':
      return (
        <MultiEmailQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          answer={
            assessmentAnswers ? assessmentAnswers[question.friendlyID] : ''
          }
          isLast={isLastQuestion}
          onSubmit={onSubmit}
        />
      )
    case 'feedback-emails':
      return (
        <FeedbackEmailsQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
          // submitting={submitting}
          isLast={isLastQuestion}
          feedbackRequest={user?.company.feedbackRequest}
        />
      )

    case 'step':
      return (
        <StepQuestion
          key={'assessment-' + index}
          progress={progress}
          question={question}
          isLast={index === assessmentQuestions.length - 1}
          isManager={isManager}
          onSubmit={onSubmit}
        />
      )
    default:
      return (
        <Text>{t('item_type_not_found', { questionType: question.type })}</Text>
      )
  }
}

export default AssessmentRouter
