import { Box, Typography, Link } from '@mui/material'
import { FC } from 'react'

interface TitleDescriptionProps {
  title: string
  description?: string | React.ReactNode
  attachment?: any
}

const TitleDescription: FC<TitleDescriptionProps> = ({
  title,
  description,
  attachment,
}) => {
  let attachmentElement = null

  if (attachment?.link) {
    if (attachment?.title.includes('<link>')) {
      const preText = attachment?.title.split('<link>')[0]
      const postText = attachment?.title.split('</link>')[1]
      const linkText = attachment?.title.split('</link>')[0].split('<link>')[1]
      attachmentElement = (
        <Typography
          fontWeight="400"
          maxWidth={600}
          lineHeight={1.3}
          fontSize="lg"
          textAlign="center"
          component="span"
        >
          {preText}
          <Link href={attachment?.link} target="_blank" rel="noreferrer">
            <Typography
              py={2}
              fontSize="lg"
              color="primary.main"
              component="span"
              sx={{
                textDecoration: 'underline',
              }}
            >
              {linkText}
            </Typography>
          </Link>
          {postText}
        </Typography>
      )
    } else {
      attachmentElement = (
        <Link href={attachment?.link} target="_blank" rel="noreferrer">
          <Typography
            py={2}
            fontSize="lg"
            color="primary.main"
            component="span"
            sx={{
              textDecoration: 'underline',
            }}
          >
            {attachment?.title}
          </Typography>
        </Link>
      )
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      pb={5}
      gap={5}
      alignItems="center"
    >
      <Typography variant="h6" textAlign="center" lineHeight={1.5}>
        {title}
      </Typography>
      {description && typeof description === 'string' ? (
        <Typography
          fontWeight="400"
          maxWidth={600}
          lineHeight={1.3}
          fontSize="lg"
          textAlign="center"
        >
          {description.split('\\n').map((str: string, index: number) => (
            <span key={index}>
              {str}
              <br />
            </span>
          ))}
        </Typography>
      ) : (
        description
      )}
      {attachmentElement}
    </Box>
  )
}

export default TitleDescription
