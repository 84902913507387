import NpsQuestion from './questiontypes/NpsQuestion'
import SelectionQuestion from './questiontypes/SelectionQuestion'
import TextAreaQuestion from './questiontypes/TextAreaQuestion'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  Modal,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect } from 'react'
import { usePostHog } from 'posthog-js/react'
import { store } from '~/app/store'
import { usePostInAppFeedbackMutation } from '~/app/services/api'
import { Logo } from '~/utils/Logo'
import { resetNpsData } from './npsSlice'
import { useDispatch } from 'react-redux'
import { useToast } from '@chakra-ui/react'

const Loading = () => {
  return (
    <Box style={{ justifyContent: 'center', marginTop: 50 }}>
      <CircularProgress />
    </Box>
  )
}

interface QuestionProps {
  onSubmit: (answer: any) => void
  question: any
}

const Question: React.FC<QuestionProps> = ({ onSubmit, question }) => {
  switch (question.type) {
    case 'nps':
      return (
        <NpsQuestion
          key={question._id}
          question={question}
          onSubmit={onSubmit}
        />
      )
    case 'textarea':
      return <TextAreaQuestion key={question._id} question={question} />
    case 'multiselect':
    case 'select':
      return (
        <SelectionQuestion
          key={question._id}
          question={question}
          onSubmit={onSubmit}
        />
      )
    default:
      return (
        <Typography>Not implemented question type: {question.type}</Typography>
      )
  }
}

interface NpsSurveyProps {
  isOpen: boolean
  onClose: () => void
}

export const NpsSurvey: React.FC<NpsSurveyProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const posthog = usePostHog()
  const dispatch = useDispatch()
  const toast = useToast()
  const { questions, answers, surveyType, surveyId, sessionId } =
    store.getState().nps
  const [postInAppFeedback] = usePostInAppFeedbackMutation()

  useEffect(() => {
    posthog.capture('nps_survey_displayed', {
      source: 'bookings',
    })
  }, [posthog])

  useEffect(() => {
    return () => {
      dispatch(resetNpsData())
    }
  }, [])

  const onSubmit = async (submitButtonClicked?: boolean) => {
    const submittedAnswers = store.getState().nps.answers
    const answersArray = Object.keys(submittedAnswers).map((key) => ({
      questionId: key,
      answer: submittedAnswers[key],
    }))
    const completed = answersArray.length === questions.length

    await postInAppFeedback({
      answers: answersArray,
      surveyType,
      surveyId,
      sessionId,
      completed,
    }).unwrap()

    if (submitButtonClicked) {
      toast({
        status: 'success',
        title: t('success'),
        description: t('thank_you_for_feedback'),
        isClosable: true,
      })
      dispatch(resetNpsData())
      onClose()
    }
  }

  if (!questions) {
    return <Loading />
  }

  return (
    <Modal
      open={isOpen && questions.length > 0}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 600,
          background: '#FFFFFF',
          borderRadius: 1,
          maxHeight: '80vh',
          overflowY: 'auto',
          padding: 4,
          position: 'relative',
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{ position: 'absolute', right: 16, top: 16 }}
        >
          <CloseIcon />
        </IconButton>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Logo md={5} />
        </Box>
        {('inapp-nps' in answers
          ? questions
          : questions.filter((q: any) => q.friendlyID === 'inapp-nps')
        )?.map((question: any) => (
          <Box mt={4} key={question._id}>
            <Divider
              style={{ marginTop: 16, marginBottom: 24, opacity: 0.6 }}
            />
            <Question question={question} onSubmit={onSubmit} />
          </Box>
        ))}
        {Object.keys(answers).length > 0 ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Button
              onClick={() => onSubmit(true)}
              variant="contained"
              color="primary"
              sx={{
                borderRadius: 100,
                height: '50px',
                fontSize: 16,
                width: '240px',
                mt: 4,
                mb: 1,
              }}
            >
              {t('submit')}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Modal>
  )
}
