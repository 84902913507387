import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MAX_ATTEMPT = 15;
const ATTEMPT_INTERVAL = 3000;

const Support = () => {
    const { userId, userName, userEmail, userCompany, userCoachEmail } = useParams() as any
    const [attemptCounter, setAttemptCounter] = useState(0);
    const [chatScreenOpen, setChatScreenOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('chatlio.ready', function (e) {
            console.log("[chatlio.ready] e: %o", e);
            // It's safe to use the API of course
            // @ts-ignore
            _chatlio.open();
            setChatScreenOpen(true);
          }, false);
    }, [])

    useEffect(() => {
        if (chatScreenOpen) {
            // @ts-ignore
            const chatlio = window._chatlio;
            if (chatScreenOpen && 'fullScreen' in chatlio) {
                chatlio.fullScreen()
            } else {
                setTimeout(() => {
                    if (attemptCounter < MAX_ATTEMPT) {
                        setAttemptCounter(attemptCounter + 1);
                    }
                }, ATTEMPT_INTERVAL)
            }
        }
    }, [chatScreenOpen, attemptCounter]);

    useEffect(() => {
        if (chatScreenOpen) {
            // Identify user in Chatlio
            // @ts-ignore
            if (window._chatlio && userId) {
                // @ts-ignore
                window._chatlio.identify(userId, {
                    name: userName,
                    email: userEmail,
                    company: userCompany,
                    coach: userCoachEmail,
                })
            }
        }
    }, [chatScreenOpen, userId, userName, userEmail, userCompany, userCoachEmail]);
    // intentionally empty
    return (<></>)
}

export default Support
